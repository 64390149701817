import React from 'react';
import { Link } from 'gatsby';
import Button from '../buttons/button';
import Prices from '../prices/prices';
import Title from '../titles/title';
import './eye-lashes-master.css';


export default function EyeLashes(props) {
    return (
        <div className="eye-lashes mb6">
            <div className="columns container-1200 mb100">
                <div className="eye-lashes-col1 column-40 posr">
                    {
                         props.data.images[0] && <img src={props.data.images[0].image} alt="" className="eye-lashes-img eye-lashes-img--1" data-sal="slide-up" data-sal-duration="1000"/>
                    }
                    {
                         props.data.images[1] && <img src={props.data.images[1].image} alt="" className="eye-lashes-img eye-lashes-img--2" data-sal="slide-up" data-sal-duration="1000"/>
                    }
                </div>
                <div className="eye-lashes-col2 column-60">
                    <Title label1={props.data.title} label2={props.data.subtitle}></Title>
                    <div className="eye-lashes-col2-content">
                    <p className='mb2'>{props.data.text}</p>
                    <Link to = "#book">
                         <Button type={2}>{props.translation.buttons.book}</Button>
                    </Link>
                    </div>
                </div>
            </div>
            <div className="columns container-1200 columns_mb">
                <div className="eye-lashes-col11 column-50 mb2">
                    <Prices path={props.pricePath} data={props.data.prices} translation={props.translation}></Prices>
                </div>
                <div className="eye-lashes-col22 column-50 posr">
                    {
                         props.data.images[2] && <img src={props.data.images[2].image} alt="" className="eye-lashes-img eye-lashes-img--3" data-sal="slide-up" data-sal-duration="1000"/>
                    }
                    {
                         props.data.images[3] && <img src={props.data.images[3].image} alt="" className="eye-lashes-img eye-lashes-img--4" data-sal="slide-up" data-sal-duration="1000"/>
                    }
                </div>
            </div>
        </div>
    )
}