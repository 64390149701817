import React from 'react';
import './title.css';

function Title(props) {
    return (
        <div className="title">
            <h1 className="col-title">{props.label1}</h1>
            <h2 className="col-subtitle fwb">{props.label2}</h2>
        </div>
    )
}
export default Title;