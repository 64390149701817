import { Link } from 'gatsby';
import React from 'react';
import Button from '../buttons/button';
import Prices from '../prices/prices';
import Title from '../titles/title';
import './hair-master.css';

function HairMaster(props){
    // console.log(props);

    return(
        <div className="hair-master mb6">
            <div className="container-1200">
                <div className="hair-master-row1 posr columns mb4">
                    <div className="hair-master-row1-col1 column-20 posr">
                        {
                            props.data.images[0] && <img src={props.data.images[0].image} alt="" className="hair-master-row1-img1" data-sal="slide-up" data-sal-duration="1000"/>
                        }

                    </div>
                    <div className="hair-master-row1-col2 column-60 hair-master-row1-text">
                        <Title label1={props.data.title} label2={props.data.subtitle}></Title>
                        <p className="mb2">{props.data.text}</p>
                        <Link to="#book">
                            <Button type={2}>{props.translation.buttons.book}</Button>
                        </Link>
                    </div>
                    <div className="hair-master-row1-col3 column-20 posr">
                        {
                             props.data.images[1] && <img src={props.data.images[1].image} alt="" className="hair-master-row1-img2" data-sal="slide-up" data-sal-duration="1000"/>
                        }
                    </div>
                </div>

                <div className="hair-master-row2 container-1200 df">
                    <div className="hair-master-row2-col1 column-60 posr">
                        {
                             props.data.images[2] && <img src={props.data.images[2].image} alt="" className="hair-master-row-img hair-master-row-img--1" data-sal="slide-up" data-sal-duration="1000"/>
                        }
                        {
                             props.data.images[3] && <img src={props.data.images[3].image} alt="" className="hair-master-row-img hair-master-row-img--2" data-sal="slide-up" data-sal-duration="1000"/>
                        }
                        {
                             props.data.images[4] && <img src={props.data.images[4].image} alt="" className="hair-master-row-img hair-master-row-img--3" data-sal="slide-up" data-sal-duration="1000"/>
                        }
                    </div>
                    <div className="hair-master-row2-col2 column-40">
                    <Prices path={props.pricePath} data={props.data.prices} translation={props.translation}></Prices>
                    </div>

                </div>

            </div>


        </div>
    )
}

export default HairMaster;