import React, { 
    // createRef, 
    // useEffect 
} from 'react';
import Title from '../titles/title';
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"
import 'lazysizes';
import './booking.css';

export default function Booking(props){

    // const reservationIframe = createRef();

    // useEffect(()=>{
    //     const handleSalIn = (e)=>{
    //       if (e.target.id === "reservationIframe70478") {
    //       //   console.warn(e);
    //         reservationIframe.current.setAttribute('src', reservationIframe.current.getAttribute('data-src'));
    //       }
    //       // else console.log(e);
    //     };
    //     document.addEventListener('sal:in', handleSalIn, {passive: true});
    //     return ()=>document.removeEventListener('sal:in', handleSalIn);
    //   })

    return(
        <div className="booking columns container-1200">
            <div className="booking-col1">
                <Title label1={props.data.title} label2={props.data.subtitle}></Title>
                <p className="mb2">{props.data.text}
                </p>
                <div className="icon-phone">
                <OutboundLink href={"tel:"+props.settings.phone} className="link-0" target="_blank" rel="noreferrer"><span className="mr1"><FiPhoneCall className="icon color-gold"></FiPhoneCall></span><span>{props.settings.phone}</span></OutboundLink>
                </div>
                <div className="icon-email">
                <OutboundLink href={"mailto:"+props.settings.email} className="link-0" target="_blank" rel="noreferrer"><span className="mr1"><FiMail className="icon color-gold"></FiMail></span><span>{props.settings.email}</span></OutboundLink>
                </div>
            </div>
            <div className="booking-col2 posr">
                <div className="booking-form">
                    <div className="booking-iframe-wrap">
                        <iframe 
                        // ref={reservationIframe} 
                        className="booking-iframe lazyload" 
                        frameBorder="0" 
                        title="Booking form" 
                        data-src="https://varaa.timma.fi/reservation/SalonAura" 
                        // src="https://varaa.timma.fi/reservation/SalonAura" 
                        id="reservationIframe70478" 
                        loading="lazy" 
                        // data-sal="slide-up" 
                        // data-sal-duration="1000"
                        ></iframe>

                    </div>

                </div>
                {/* <img src="https://res.cloudinary.com/salon-aura/image/upload/f_auto,q_auto/v1619277684/website/book-form_wfc3y1.png" alt="" className="booking-img"/> */}
                {/* <div className="booking-shadow"></div> */}
            </div>
        </div>
    )
}