import { Link } from 'gatsby';
import React from 'react';
import Button from '../buttons/button';
import Prices from '../prices/prices';
import Title from '../titles/title';
import './nail-master.css';


export default function NailMaster(props){
    return (
        <div className="nail-master container-1200 mb6">
            <div className="columns  df">
                <div className="nail-col1 column-60">
                    <Title label1={props.data.title} label2={props.data.subtitle}></Title>
                    <p className="col-text">{props.data.text}</p>
                    <Link to = "#book">
                         <Button type={2}>{props.translation.buttons.book}</Button>
                    </Link>
                         <div className="mb100"></div>
                         <Prices path={props.pricePath} data={props.data.prices} translation={props.translation}></Prices>
                </div>
                {/* <div className="nail-col1 mr40">
                </div> */}
                <div className="nail-col2 df-separator posr">
               
                { props.data.images[0] && (<img src={props.data.images[0].image} alt="" className="nail-pic nail-pic--1" data-sal="slide-up" data-sal-duration="1000"/>) }
                { props.data.images[1] && (<img src={props.data.images[1].image} alt="" className="nail-pic nail-pic--2" data-sal="slide-up" data-sal-duration="1000"/>) }
                { props.data.images[2] && (<img src={props.data.images[2].image} alt="" className="nail-pic nail-pic--3" data-sal="slide-up" data-sal-duration="1000"/>) }
             
                </div>
            </div>

        </div>
    )
}