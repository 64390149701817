import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import StripeGallery from "./stripeGallery"
import { getRandomInt } from "../utils/utils"
import ReactMarkdown from "react-markdown"
import Title from "./titles/title"
import "./IndexGallery.css"
// import ReactMarkdown from "react-markdown"

const IndexGallery = (props) => {

  const data = useStaticQuery(graphql`
  query randomGallery {
    
    cloudinaryImages: allCloudinaryItem {
      nodes {
        id
        public_id
        thumb
        imgUrl
        width
        height
        orientation
      }
    }
  }
`);
// console.warn(data);

const [pictures, setPictures] = useState([]);
const [albumsPics] = useState(props.data.images || []);
const [cloudinaryImages] = useState(data.cloudinaryImages.nodes);

  useEffect(()=>{
      const fileNameRegExp = /\/([^/]+)\.\w{1,4}$/;
  
      let randomPics = []
      while (randomPics.length < (albumsPics.length < 10 ? albumsPics.length : 10)) {
        let index = getRandomInt(0, albumsPics.length - 1);
        if (!randomPics.includes(albumsPics[index])) {
          randomPics.push(albumsPics[index]);
        }
      }
      // console.log(cloudinaryImages);
      let pictures = randomPics.map((pic, index) => {
        const res = fileNameRegExp.exec(pic.image);
        if (!res || !res[1]) return undefined;
        const picData = cloudinaryImages.filter(pic => pic.public_id.includes(res[1]))[0];
        if (!picData) return undefined; 
        const deg = 0;
        // const deg = getRandomInt(-5, 5);
        return {
          index: index,
          type: picData.type || "image",
          id: picData.id,
          src: picData.imgUrl,
          thumbnail: picData.thumb,
          w: picData.width,
          h: picData.height,
          deg: deg,
          title: pic.title || "",
          description: picData.description || "",
        };
      });

      setPictures(pictures);
      // console.warn("RandomGallery pictures", pictures);

  }, [albumsPics, cloudinaryImages])


    const getThumbnailContent = (item) => {
      
      return (
        <>
        {
          item ? <div className="gallery-stripe-item__in" style={{transform: `rotate(${item ? item.deg : 0}deg)`}}>
          <img src={item.thumbnail} alt={item.title} loading="lazy" className="gallery-item__pic gallery-item__pic--h330 mb1"/>
          <h5 className="mb0">{item.title}</h5>
        </div> : <div></div>
        }
          
        </>
      );
    }

    return (
       <div className="page">
         <div className="aura-gallery-header page__in columns container-1200">
           <div className="column-50">
              {/* <h1 className="color-gold">{props.data.title}</h1> */}
              <Title label1={props.data.title} label2={props.data.subtitle}></Title>
           </div>
           <div className="column-50">
            <ReactMarkdown source={props.data.content} linkTarget="_blank"/>
           </div>
         </div>
          <StripeGallery items={pictures} options={{}} thumbnailContent={getThumbnailContent}/>
        </div>
    )
  }

export default IndexGallery


