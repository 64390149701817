import React from 'react';
import Title from '../titles/title';
import './news.css';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Button from '../buttons/button';
import slugHandler from '../../api/slugHandlerBrowser';


function News(props) {
    // console.log(props);
    return (
        <div className="news container-1200">
                
                <div className="news-list df">
                    {
                        props.data.news.map((post, i) => {
                            const slug = post.slug.split('.')[0];
                            const path = slugHandler(
                                props.data.translation.code,
                                slug
                              );
                            return(
                            <div className="news-item column-33" key={i}>
                                {
                                    i===0 && <Title label1={props.data.title} label2={props.data.subtitle}></Title>

                                }
                                <div className="news-item__in">
                                    <img src={post.frontmatter.image} alt="new" className="news-item-img mb20" data-sal="slide-up" data-sal-duration="1000"/>
                                    <div className="news-item-info posr">
                                        <div className="news-item-date mb1">{post.frontmatter.date}</div>
                                        <h3 className="news-item-title mb1">{post.frontmatter.title}</h3>
                                        {/* <div className="news-item-contain mb20">{post.frontmatter.description}</div> */}
                                        <ReactMarkdown source={post.frontmatter.description} linkTarget="_blank" className="news-item-text mb1"/>
                                        <Link to={path} className="link">{props.data.translation.buttons.readMore}</Link>   
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }

                </div>
                <div className="news-actions tac">
                <Link to={slugHandler(props.data.translation.code, "/news")}>
                    <Button>{props.data.translation.buttons.showAll}</Button>
                    </Link>
                </div>
           
        </div>
    )
}

export default News 