import { Link } from 'gatsby';
import React from 'react';
import {RiArrowRightUpLine} from 'react-icons/ri';
import './prices.css';


// let itemPrice = {
//         title: "Hair care price",
//         list: [
//             {
//                 title:'Hair care',
//                 price: '50 $',
//             },
//             {
//                 title:'Haircut',
//                 price: '25 $',
//             },
//             {
//                 title:'Hair coloring',
//                 price: '70 $',
//             },
//             {
//                 title:'Hair styling',
//                 price: '60 $',
//             },

//         ]
      
// }
function Prices(props) {
    // console.warn(props);
    return (
        <div className="price">
            <h3 className="price-title fwb color-gold">{props.data.title}</h3>
            {
                props.data.list.map((row,i) => <div className="price-row df" key={i}>
                <span className="price-row-title">{row.title}</span>
                <span className="price-row-space df-separator"></span>
                <span className="price-row-price">{row.price}</span>
            </div>)
            }
           <div className="price-actions tar">
            <Link to={props.path} className="price-btn tac"><span className="mr05 vam">{props.translation.buttons.showAll}</span><RiArrowRightUpLine/></Link>
           </div>
        </div>
    )
}

export default Prices;