import React from 'react';
import Button from '../buttons/button';
import ReactMarkdown from "react-markdown"
import './hero-content.css';
import Title from '../titles/title';
import { Link } from 'gatsby';
// import slugHandler from '../../api/slugHandlerBrowser';

function HeroContent (props){
    // console.log(props);
    return(
        <div className="hero">
            <Title label1={props.data.title} label2={props.data.subtitle} className="hero-title"></Title>
            <div className="hero-col1 column-66">
                <div className="text">
                    <ReactMarkdown source={props.data.content} className="text"/>
                </div>
                <div className="hero-col1-footer">
                    <div className="hero-btns df fww">
                    <Link to="#book">
                        <Button type={1} className="mr2 mb1">{props.translation.buttons.book}</Button>
                    </Link>
                    {/* <Link to="#about">
                        <Button type={2}className="mr2">{props.translation.buttons.readMore}</Button>
                    </Link> */}
                    </div>
                    <div className="tar">
                        <img src={props.data.sign} alt="signature" className="hero-signature"/>
                    </div>
                </div>
            </div>
                <img src={props.data.image} alt="hero" className="hero-img"/>
            

        </div>
    )
}

export default HeroContent

