import React from 'react';
import ReactMarkdown from 'react-markdown';
import Title from '../titles/title';
import ContactsMap from './contacts-map/contacts-map';
import './contacts.css';
import FeedbackForm from './feedback-form/feedback-form';


export default function Contacts(props) {
    // console.log(props);
    return (
        <div className="contacts mb6">
            <ContactsMap></ContactsMap>
            <div className="contacts-in container-900">
                <div className="contacts-cols">
                    <div className="contacts-col1 column-50 mr2">
                        <Title label1={props.data.title} label2={props.data.subtitle}></Title>
                        <ReactMarkdown source={props.data.text} linkTarget="_blank"/>
                    </div>
                    <div className="contacts-col2 column-50">
                        <FeedbackForm data={props.translation}></FeedbackForm>
                    </div>
                </div>
            </div>

        </div>

    )
}