import React from 'react';
import './contacts-map.css';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { mapStyle } from './contacts-map-style';

const mapId = 'f5cf52e2a0a45ee7';
const apiKey="AIzaSyCHxf8Sz9ci6AQCE0_LKz-8k1DfLQviriY"


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 60.244991840050936,
  lng: 24.854998877040295
};



function ContactsMap() {

  return (
    <LoadScript
      googleMapsApiKey={apiKey}
      mapId={mapId}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        mapId={mapId}
        onLoad={map => {
            map.setOptions({
                styles: mapStyle,
                // mapId: mapId
             })
          }}
      >
          <Marker
          position={center}
          icon="https://res.cloudinary.com/salon-aura/image/upload/v1619862086/website/map-pin_qhos7p.png"
          ></Marker>
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(ContactsMap)