import React,{ useState } from 'react';
import { formEncode } from '../../../utils/utils';
import Button from '../../buttons/button';
import './feedback-form.css';

export default function FeedbackForm(props) {
    // console.log(props);

    const [form, setForm] = useState(
        {
            firstName: "",
            lastName: "",
            subject: "",
            message: "",
        }
    );

    const [popupText, setPopupText] = useState();

    function handleChange(e) {
        const newValue = e.target.value;
        const fieldName = e.target.name;
        const newForm = { ...form };
        newForm[fieldName] = newValue;
        // console.log(newFormValue);
        setForm(newForm);
    }

    function handleSubmit(e) {
        e.preventDefault();
        fetch('/', {
          method: 'POST',
          body: formEncode({
            'form-name': "contact",
            ...form,
          }),
        })
        .then(() => {

            // console.warn(form);
            setPopupText(<div className="feedback-form-text">{props.data.feedbackForm.successText}</div>);
        //   navigate(form.getAttribute('action'))
        })
        .catch((error) => {
            console.error(error);
            setPopupText(
                <div className="feedback-form-text">
                    <p className="mb1">{props.data.feedbackForm.errorText}</p>
                    <Button type={2} className="form-btn" onClick={()=>setPopupText(false)}>{props.data.buttons.ok}</Button>
                </div>
            );
        });
    }

    
    return (
        <div className="feedback-form posr">
            <div className="feedback-form-in posr">
            
           {
            popupText && <div className="feedback-form-popup">
                {popupText}
            </div>
            }
            
           {
            <form action="POST" name="contact" id="contact" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}
             className={popupText ? "feedback-form--invisible" : ""}>
            <input type="hidden" name="form-name" value="contact" />
                <div className="form-field">
                <label>
                    <input type="text" name="firstName" title="firstName" id="firstName" placeholder={props.data.feedbackForm.name} value={form.firstname} onChange={handleChange} required></input>
                </label>
                </div>
                <div className="form-field">
                <label>
                    <input type="text" name="lastName" title="lastName" id="lastName" placeholder={props.data.feedbackForm.lastName} value={form.lastname} onChange={handleChange} required></input>
                </label>
                </div>
                <div className="form-field">
                <label>
                    <input type="text" name="subject" title="subject" id="subject" placeholder={props.data.feedbackForm.subject} value={form.subject} onChange={handleChange} required></input>
                </label>
                </div>
                <div className="form-field">
                <label>
                    <textarea name="message"  tittle="message" id="message" rows="5" cols="40" wrap="hard" placeholder={props.data.feedbackForm.text} value={form.message} onChange={handleChange} required></textarea>
                </label>
                </div>
                <Button type={1} className="form-btn">{props.data.buttons.send}</Button>
            </form>
            }
            </div>
        </div>
    )
}