import React from 'react';
import './about.css';
import Title from '../titles/title';
import Testimonials from '../testimonials/testimonials';
function About({ data }) {
    // console.log(data);
    return (
        <div className="about container-1200">
            <div className="about-main columns">
                <div className="about-col1 column-40 mr2">
                    <Title label1={data.about.title} label2={data.about.subtitle}></Title>
                    <p className="mb60 about-col1-text ">{data.about.content}</p>
                </div>
                <div className="about-col2 column-60 posr">
                {
                    data.about.images.map((image, i) => 
                    <img key={i} src={image.image} alt="salon" className={"about-image about-image--"+ (i+1)} data-sal="slide-up" data-sal-duration="1000"/>
                    )
                }
                </div>
            </div>
            <div className="about-testimonials columns">
                <div className="about-testimonials-col1 column-50"></div>
                <div className="about-testimonials-col2 column-50">
                    <div className="testimonials posr">
                        <Title label1={data.testimonials.title} label2={data.testimonials.subtitle}></Title>
                        <Testimonials data={data.testimonials.list}></Testimonials>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default About