import React, { useEffect, useState } from 'react';
import quotes from '../../assets/img/quotes.svg';
import './testimonials.css'

export default function Testimonials(props) {
    // console.log(props);
    let [active, setActive] = useState(0);

    useEffect(()=>{
        const id = setInterval(()=>{
            // console.warn("Testimonial", active+1);
            if(props.data.length-1 === active){
                setActive(0);
            } else {
                setActive(active+1);
            }
        }, 5000);
        return ()=>clearInterval(id);
    }, [active, props.data.length])
    
    return (
        <div className="testimonial-content">
            <img src={quotes} alt="quotes" className="testimonial-quotes" />
            {
                props.data.map((item, i) => {

                    let classes = "testimonial-text" + (i === active ? " testimonial-text--active" : "");
                    return (
                        <div key={i} className={classes}>
                            <div className="testimonial-text-in df jcc ais">
                                <p>{item.text}</p>
                            </div>
                            <div className="testimonial-name-in posr">
                                <span className="testimonial-name mb40">— {item.name}</span>
                            </div>
                        </div>
                    )

                })
            }
            <div className="testimonials-points">
                {/* <button aria-label="testimonial 1" className="testimonials-point testimonials-point__active"></button> */}
                {
                    props.data.map((item, i) =>
                        <button key={i} aria-label={"testimonial bullet " + i}
                            className={"testimonials-point" + (i === active ? " testimonials-point--active" : "")}
                            onClick={() => setActive(i)}>
                        </button>
                    )
                }
            </div>
        </div>
    )
}