export function chunkifyArray(a, n, balanced) {
    
    if (n < 2)
        return [a];
  
    var len = a.length,
            out = [],
            i = 0,
            size;
  
    if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
            out.push(a.slice(i, i += size));
        }
    }
  
    else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, i += size));
        }
    }
  
    else {
  
        n--;
        size = Math.floor(len / n);
        if (len % size === 0)
            size--;
        while (i < size * n) {
            out.push(a.slice(i, i += size));
        }
        out.push(a.slice(size * n));
    }
  
    return out;
  }

export function getRandomInt(min, max) {
    return min + Math.floor(Math.random() * (max - min + 1));
}

export function isNotInViewport(rect) {
    return (
        rect.top > (window.innerHeight || document.documentElement.clientHeight) ||
        rect.bottom < 0 
    );
}

export function encodeObject(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  export function formEncode(data) {
    const formData = new FormData()
  
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
  
    return formData
  }
  
