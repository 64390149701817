import React from "react"
import { graphql } from "gatsby"
// import ReactMarkdown from "react-markdown"
// import Image from "gatsby-image"
import SEO from "../components/seo"
import TopMenu from "../components/topMenu"
// import slugHandler from "../api/slugHandlerBrowser"

import Modal from 'react-modal';
import About from "../components/about/about"
import HeroContent from "../components/hero/hero-content"
import News from "../components/news/news"
// import Price from "../components/prices/prices"
import HairMaster from "../components/hair-master/hair-master"
import NailMaster from "../components/nail-master/nail-master"
import EyeLashes from "../components/eye-lashes-master/eye-lashes-master"
import Pattern from "../components/pattern/pattern"
import Booking from "../components/booking/booking"
// import Footer from "../components/footer"
import Contacts from "../components/contacts/contacts"
import Footer from "../components/footer"
import IndexGallery from "../components/IndexGallery";
import slugHandler from "../api/slugHandlerBrowser";

Modal.defaultStyles.overlay.zIndex = '20';
Modal.setAppElement('#___gatsby');

const IndexPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.page;
  const slug = data.page.fields.slug;
  const news = data.news.nodes;
  const settings = data.settings.childMdx.frontmatter;
  const services = page.services;
  const servicesComponents = [
    HairMaster,
    NailMaster,
    EyeLashes,
  ]
  // console.log(settings);


  // const heroDiv = createRef();
  // const [menuVisible, setMenuVisible] = useState(false);
  // useEffect(()=>{
  //   window.addEventListener("scroll", checkMenuVisible);
  //   return ()=>{
  //     window.removeEventListener("scroll", checkMenuVisible);
  //   }
  // })
  // function checkMenuVisible() {
  //   console.log(heroDiv.current);
  //   if(!heroDiv.current) return;

  //   if (heroDiv.current.getBoundingClientRect().bottom < 0){
  //     // console.log("visible")
  //     setMenuVisible(true);
  //   }
  //   else{
  //     // console.log("invisible")
  //       setMenuVisible(false);
  //   }
  // }



  return (
    <section className={"page au-lang-" + pageContext.language}>
      <SEO
        title={page.title}
        description={page.description}
      />
    {/* <div className="container-1200"> */}
    <div className="page__in">
      <section className="aura-hero container-1200 mb6">
        <TopMenu lang={page.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation} settings={settings}></TopMenu>
        <HeroContent data={page.hero}  translation={pageContext.translation} >
        </HeroContent>
        </section>
        <section className="aura-about mb6" id="about">
        <About data={{about: page.about, testimonials:page.testimonials}}></About>
        </section>
        <section className="aura-news mb6">
          <News data={{...page.news, news, translation:pageContext.translation, settings:settings}} ></News>
        </section>
        <div className="" id="services"></div>
          {
           services && (services.filter(service => service.active).map((service, i) => 
              React.createElement(servicesComponents[i], {
                key: i,
                data: service,
                pricePath: slugHandler(page.language, "/price"),
                translation: pageContext.translation,
              })
            ) )
          }

      </div>
        {
          page.gallery.active && <section className="aura-gallery mb6">
              <IndexGallery data={page.gallery}></IndexGallery>
            </section>
        }
        {
          page.banner.active && <section className="aura-pattern mb6">
              <Pattern data={page.banner}></Pattern>
            </section>
        }
      <div className="page__in">
        <section className="aura-booking mb6" id="book">
          <Booking data={page.book} settings={settings}></Booking>
        </section>
        <section className="aura-footer" id="contacts">
          {/* <img src="https://res.cloudinary.com/salon-aura/image/upload/f_auto,q_auto/v1619626136/website/map_cf6myj.jpg" alt="" className="footer-map" style={{width: "100%"}}/> */}
          
          <Contacts settings={settings} data={page.contacts} translation={pageContext.translation}></Contacts>
          <Footer lang={pageContext.language} languages={pageContext.languages} translation={pageContext.translation}></Footer>

        </section>
      </div>
    </section>
  )
};
  

export const indexQuery = graphql`
  query IndexById($id: String!, $language: String!, $langSlugRegEx: String!) {
    page: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/index" }
        language: { eq: $language }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
        contacts {
          title
          subtitle
          text
        }
        about {
          title
          subtitle
          content
          images {
            image
          }
        }
        testimonials {
          list {
            name
            text
          }
          subtitle
          title
        }
        hero {
          title
          subtitle
          content
          image
          link
          sign
        }
        book {
          title
          subtitle
          text
        }
        banner {
          title
          subtitle
          caption
          active
        }
        news {
          title
          subtitle
        }
        gallery {
          title
          subtitle
          content
          images {
            image
          }
          active
        }
        services {
          title
          subtitle
          text
          prices {
            title
            list {
              title
              price
            }
          }
          images {
            image
          }
          active
        }
      }
    }
    news: allMdx(filter: {frontmatter: {templateKey: {eq: "post"}, tags: {regex: "/news/"}}, slug: {regex: $langSlugRegEx }}, sort: {fields: [frontmatter___date], order: DESC}, limit: 3) {
      nodes {
        slug
        id
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          title
          image
          description
        }
      }
    }
    price: mdx(
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/price" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        title
        description
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          detectLanguage
          address
          address2
          phone
          instagram
          facebook
          email
          pinterest
          googlemaplink
        }
      }
    }
  }
`
export default IndexPageTemplate
